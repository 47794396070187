<template>
  <GenericConnectionData
      :connectionId="connectionId"
      :title="title"
      :type="type"
      :breadcrumbsItems="breadcrumbsItems"
      @update-connection="updateConnection"
  >
    <template v-slot:request-components>
      <div class="col-lg-3 col-md-4">
        <BynderMetapropertiesDataRequest
            id="metaproperties"
            class="mt-3"
            :title="$t('connection.data.bynder.metaproperties')"
            :connection="connection"
        />
      </div>
      <div class="col-lg-3 col-md-4">
        <BynderMetapropertyOptions
            id="metaproperty options"
            class="mt-3"
            :title="$t('connection.data.bynder.metaproperty_options')"
            :connection="connection"
        />
      </div>
      <div class="col-lg-3 col-md-4">
        <BynderAssetsDataRequest
            id="assets"
            class="mt-3"
            :title="$t('connection.data.bynder.assets')"
            :connection="connection"
        />
      </div>
      <div class="col-lg-3 col-md-4">
        <BynderWebhooksDataRequest
            id="webhooks"
            class="mt-3"
            :title="$t('connection.data.bynder.webhooks')"
            :connection="connection"
        />
      </div>
    </template>
  </GenericConnectionData>
</template>

<script>
import {useToast} from "vue-toastification";
import securityMixin from "@/mixins/securityMixin";
import GenericConnectionData from "@/views/Connection/Data/GenericConnectionData.vue";
import BynderMetapropertiesDataRequest from "@/views/Connection/Data/Bynder/DataRequest/BynderMetapropertiesDataRequest.vue";
import BynderMetapropertyOptions
  from "@/views/Connection/Data/Bynder/DataRequest/BynderMetapropertyOptionsDataRequest.vue";
import BynderAssetsDataRequest
  from "@/views/Connection/Data/Bynder/DataRequest/BynderMetapropertyAssetsDataRequest.vue";
import BynderWebhooksDataRequest from "@/views/Connection/Data/Bynder/DataRequest/BynderWebhooksDataRequest.vue";

export default {
  name: "AkeneoConnectionData",
  components: {
    BynderWebhooksDataRequest,
    BynderAssetsDataRequest,
    BynderMetapropertyOptions,
    BynderMetapropertiesDataRequest,
    GenericConnectionData,
  },
  mixins: [securityMixin],
  setup () {
    const toast = useToast();

    return {toast};
  },
  props: {
    connectionId: String,
    title: String,
    type: String,
    breadcrumbsItems: Object
  },
  methods: {
    updateConnection(updatedConnection) {
      this.connection = updatedConnection;
    },
  },
  data () {
    return {
      connection: {},
    }
  },
}
</script>

<style scoped>

</style>
