<template>
  <GenericConnectionData
      :connectionId="connectionId"
      :title="title"
      :type="type"
      :breadcrumbsItems="breadcrumbsItems"
      @update-connection="updateConnection"
  >
    <template v-slot:request-components>
      <div class="col-lg-3 col-md-4">
        <ShopifyProductByIdDataRequest
            id="product-by-id"
            class="mt-3"
            :title="$t('connection.data.shopify.product_by_id')"
            :connection="connection"
        />
      </div>
      <div class="col-lg-3 col-md-4">
        <ShopifyProductSearchDataRequest
            id="product-search"
            class="mt-3"
            :title="$t('connection.data.shopify.product_search')"
            :connection="connection"
        />
      </div>
      <div class="col-lg-3 col-md-4">
        <ShopifyProductVariantSearchDataRequest
            id="product-variant-search"
            class="mt-3"
            :title="$t('connection.data.shopify.product_variant_search')"
            :connection="connection"
        />
      </div>
      <div class="col-lg-3 col-md-4">
        <ShopifyFileSearchDataRequest
            id="file-search"
            class="mt-3"
            :title="$t('connection.data.shopify.file_search')"
            :connection="connection"
        />
      </div>
      <div class="col-lg-3 col-md-4">
        <ShopifyMetafieldDefinitionByIdDataRequest
            id="metafield-by-id"
            class="mt-3"
            :title="$t('connection.data.shopify.metafield_by_id')"
            :connection="connection"
        />
      </div>
      <div class="col-lg-3 col-md-4">
        <ShopifyMetafieldDefinitionSearchDataRequest
            id="metafield-search"
            class="mt-3"
            :title="$t('connection.data.shopify.metafield_definitions_search')"
            :connection="connection"
        />
      </div>

      <div class="col-lg-3 col-md-4">
        <ShopifyFileByIdDataRequest
            id="product-by-id"
            class="mt-3"
            :title="$t('connection.data.shopify.file_by_id')"
            :connection="connection"
        />
      </div>
    </template>
  </GenericConnectionData>
</template>

<script>
import {useToast} from "vue-toastification";
import securityMixin from "@/mixins/securityMixin";
import GenericConnectionData from "@/views/Connection/Data/GenericConnectionData.vue";
import ShopifyProductByIdDataRequest from "@/views/Connection/Data/Shopify/DataRequest/ShopifyProductByIdDataRequest.vue";
import ShopifyProductSearchDataRequest from "@/views/Connection/Data/Shopify/DataRequest/ShopifyProductSearchDataRequest.vue";
import ShopifyProductVariantSearchDataRequest
  from "@/views/Connection/Data/Shopify/DataRequest/ShopifyProductVariantSearchDataRequest.vue";
import ShopifyFileSearchDataRequest from "@/views/Connection/Data/Shopify/DataRequest/ShopifyFileSearchDataRequest.vue";
import ShopifyMetafieldDefinitionByIdDataRequest from "@/views/Connection/Data/Shopify/DataRequest/ShopifyMetafieldDefinitionByIdDataRequest.vue";
import ShopifyMetafieldDefinitionSearchDataRequest from "@/views/Connection/Data/Shopify/DataRequest/ShopifyMetafieldDefinitionSearchDataRequest.vue";
import ShopifyFileByIdDataRequest from "@/views/Connection/Data/Shopify/DataRequest/ShopifyFileByIdDataRequest.vue";

export default {
  name: "ShopifyConnectionData",
  components: {
    ShopifyFileByIdDataRequest,
    ShopifyProductVariantSearchDataRequest,
    ShopifyMetafieldDefinitionSearchDataRequest,
    ShopifyMetafieldDefinitionByIdDataRequest,
    ShopifyFileSearchDataRequest,
    ShopifyProductByIdDataRequest,
    ShopifyProductSearchDataRequest,
    GenericConnectionData,
  },
  mixins: [securityMixin],
  setup () {
    const toast = useToast();

    return {toast};
  },
  props: {
    connectionId: String,
    title: String,
    type: String,
    breadcrumbsItems: Object
  },
  methods: {
    updateConnection(updatedConnection) {
      this.connection = updatedConnection;
    },
  },
  data () {
    return {
      connection: {},
    }
  },
}
</script>

<style scoped>

</style>
