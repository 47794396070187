<template>
  <div class="w-75">
    <ToggleField
        :id="type + 'display_id'"
        labelClass="fs-6 fw-bold"
        :label="$t('form.akeneoBynder.search.' + this.type + '.toggle')"
        :error="getErrorMessage('display_id')"
        v-model="hasSearch"
    />
    <div v-if="hasSearch">
      <p v-if="type === 'product'" class="mb-0">
        <a href="https://api.akeneo.com/documentation/filter.html#filter-on-product-properties" target="_blank">{{$t('form.akeneoBynder.search.product.link')}}</a>.
      </p>
      <p v-if="type === 'product_model'" class="mb-0">
        <a href="https://api.akeneo.com/documentation/filter.html#filter-on-product-model-properties" target="_blank">{{$t('form.akeneoBynder.search.product_model.link')}}</a>.
      </p>
      <JsonField
          class=""
          :id="type + 'search_filters'"
          :showBtns="false"
          :rows=3
          :modes="['code']"
          v-model="search"
      />
    </div>
  </div>
</template>

<script>

import ToggleField from "@/components/Form/ToggleField.vue";
import JsonField from "@/components/Form/JsonField.vue";

export default {
  name: "ProductIdentifierToBynderOptionField",
  components: {
    JsonField,
    ToggleField,
  },
  props: {
    id: String,
    modelValue: Object,
    errors: Object,
    type: {
      type: String,
      default: 'product'
    }
  },
  data () {
    return {
      hasSearch: !!this.modelValue && this.modelValue !== '{}' && this.modelValue !== '[]' && JSON.stringify(this.modelValue) !== "{}" && JSON.stringify(this.modelValue) !== '[]',
      search: this.modelValue !== "{}" ? JSON.stringify(this.modelValue) : "{}",
      formErrors: {},
    }
  },
  watch: {
    hasSearch (newValue) {
      if (!newValue) {
        this.search = "{}"
      }
    },
    search: "updateModel",
  },
  methods: {
    updateModel () {
      if (!this.hasSearch) {
        this.$emit("update:modelValue", "{}");
      } else {
        this.$emit("update:modelValue", JSON.parse(this.search));
      }
    },
    getErrorMessage (field) {
      if (this.errors && this.errors[field]) {
        return this.errors[field];
      }
      if (this.formErrors && this.formErrors[field]) {
        return this.formErrors[field];
      }

      return null;
    },
  },
  mounted () {
  },
}
</script>
