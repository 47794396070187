<template>
  <div class="container-fluid">
    <Breadcrumbs :items="breadcrumbs"/>
    <HeaderTitle :title="$t('connection.plural')">
      <template v-slot:under-title-area>
        <SelectField
            v-if="companyOptions.length > 0 && isGranted('ROLE_ADMIN')"
            id="company"
            :label="$t('job.jobInstance.property.company_name')"
            v-model="companyCode"
            :options="companyOptions"
        />
      </template>
      <template v-slot:button-area>
        <router-link class="m-1 btn btn-primary" to="/connections/create">
          <i class="bi bi-plus-lg"/>
          {{ $t('connection.create') }}
        </router-link>
      </template>
    </HeaderTitle>

    <ConnectionSummaries
        class="mt-3"
        :title="$t('connection.summary.akeneo.title')"
        :companyCode="companyCode"
        connectionType="akeneo"
    />
    <ConnectionSummaries
        class="mt-3"
        :title="$t('connection.summary.bynder.title')"
        :companyCode="companyCode"
        connectionType="bynder"
    />
    <ConnectionSummaries
        class="mt-3"
        :title="$t('connection.summary.shopify.title')"
        :companyCode="companyCode"
        connectionType="shopify"
    />

  </div>
</template>

<script>
import Breadcrumbs from "@/components/Page/Breadcrumbs";
import HeaderTitle from "@/components/Page/Content/HeaderTitle";
import ConnectionSummaries from "@/views/Connection/Summary/ConnectionSummaries.vue";
import {useToast} from "vue-toastification";
import SelectField from "@/components/Form/SelectField.vue";
import CompanyService from "@/services/company.service";
import securityMixin from "@/mixins/securityMixin";

export default {
  name: "ConnectionIndex",
  mixins: [securityMixin],
  components: {
    SelectField,
    ConnectionSummaries,
    Breadcrumbs,
    HeaderTitle,
  },
  data () {
    return {
      companyOptions: [],
      companyCode: this.getInitCompanyCode(),
    }
  },
  setup () {
    const toast = useToast();

    return {toast};
  },
  computed: {
    breadcrumbs () {
      return [
        {name: 'home', label: this.$i18n.t('common.home'), link: '/'},
        {name: 'connection', label: this.$i18n.t('connection.plural'), link: '/connections'},
      ]
    },
  },
  methods: {
    getInitCompanyCode() {
      const companyCode = localStorage.getItem('connection_company_code') ?? null
      if(companyCode == 0) {
        return null;
      }

      return companyCode;
    }
  },
  async beforeMount () {
    await CompanyService.getCompaniesList()
        .then((response) => {
          for (let company of response.data['hydra:member']) {
            this.companyOptions.push({
              id: company.code,
              text: company.name
            });
          }
        })
        .catch(error => console.log(error));

    this.isLoading = false;
    if (this.$route.query.sucess_message) {
      this.toast.success(this.$i18n.t(this.$route.query.sucess_message));
    }
  },
  watch: {
    companyCode: {
      handler (companyCode) {
        if(!companyCode) {
          localStorage.setItem('connection_company_code', null);
        }
        localStorage.setItem('connection_company_code', companyCode);
      },
      deep: true
    },
  }
}
</script>

<style lang="scss">

</style>
