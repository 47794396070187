<template>
  <div class="container-fluid">
    <Breadcrumbs :items="breadcrumbs"/>
    <h3> {{ $t('dashboard.title')}} </h3>
    <InQueueJobExecutions/>
    <LastJobExecutions/>
    <LastErrorJobExecutions/>
  </div>
</template>

<script>
import {mapState} from "vuex";
import LastJobExecutions from "@/components/Widget/LastJobExecutions";
import InQueueJobExecutions from "@/components/Widget/InQueueJobExecutions";
import LastErrorJobExecutions from "@/components/Widget/LastErrorJobExecutions";
import Breadcrumbs from "@/components/Page/Breadcrumbs";

export default {
  name: 'Home',
  components: {Breadcrumbs, LastErrorJobExecutions, InQueueJobExecutions, LastJobExecutions},
  computed: {
    ...mapState({
      currentUser: state => state.auth.user
    }),
    breadcrumbs() {
      return [
        {name: 'home', label: this.$i18n.t('common.home'), isCurrent: true}
      ]
    }
  },
};
</script>
