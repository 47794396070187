<template>
  <div class="card mt-3 mb-3">
    <div class="card-header">
      {{ $t('widget.last-error-job-execution.title') }}
    </div>
    <div class="card-body">
      <Loader v-if="isLoaded"/>
      <template v-else>
        <JobExecutionGrid v-if="rows.length > 0" :items="rows"/>
        <p class="text-center" v-else>
          {{ $t('widget.last-error-job-execution.empty-result') }}
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import JobExecutionGrid from "@/components/Job/JobExecution/JobExecutionGrid";
import JobService from "@/services/job.service";
import Loader from "@/components/Page/Loader";

export default {
  name: "LastErrorJobExecutions",
  components: {Loader, JobExecutionGrid},
  data() {
    return {
      isLoaded: false,
      rows: [],
      totalCount: 0
    }
  },
  beforeMount() {
    this.isLoaded = true;
    this.getRows();
  },
  methods: {
    getRows() {
      JobService.getJobExecutions(1, {
        status: 'error',
        itemsPerPage: 10,
        ["createdAt[after]"]: new Date(new Date().setDate(new Date().getDate() - 7))
      })
          .then((response) => {
            this.rows = response.data['hydra:member'];
            this.isLoaded = false;
          })
          .catch(error => console.log(error));
    }
  },
  mounted: function () {
    this.timer = setInterval(() => {
      this.getRows()
    }, 5000);
  },
  beforeUnmount() {
    clearInterval(this.timer)
  }
}
</script>

<style lang="scss">
.card-body {
  table {
    margin-bottom: 0;

    tbody {
      tr:last-child {
        border: none;
        border-color: transparent;
        border-style: none;
        border-width: 0;
      }
    }
  }
}
</style>
