<template>
  <div v-if="alertMessage" :class="`alert ${alertType} alert-dismissible`" role="alert">
    <button type="button" class="btn-close" aria-label="Close" @click="closeAlert"></button>
    {{ alertMessage }}
  </div>
  <table v-if="items.length > 0" class="table table-hover">
    <thead>
    <tr>
      <th scope="col">{{ $t('common.id') }}</th>
      <th scope="col">{{ $t('user.property.username') }}</th>
      <th v-if="isGranted('ROLE_ADMIN')" scope="col">{{ $t('user.property.company.name') }}</th>
      <th scope="col">{{ $t('common.property.createdAt') }}</th>
      <th scope="col">{{ $t('common.property.updatedAt') }}</th>
      <th scope="col">{{ $t('user.property.lastConnection') }}</th>
      <th v-if="isGranted('ROLE_ADMIN')" scope="col"></th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="item in items" :key="item.id" @click="$router.push('/user/' + item.id)">
      <td>{{ item.id }}</td>
      <td>{{ item.username }}</td>
      <td v-if="isGranted('ROLE_ADMIN')">{{ item.company.name }}</td>
      <td>
        <DateFormatter v-if="item.createdAt" :value="item.createdAt" format="DD/MM/YYYY HH:mm:ss"/>
      </td>
      <td>
        <DateFormatter v-if="item.updatedAt" :value="item.updatedAt" format="DD/MM/YYYY HH:mm:ss"/>
      </td>
      <td>
        <DateFormatter v-if="item.lastConnectionDate" :value="item.lastConnectionDate" format="DD/MM/YYYY HH:mm:ss"/>
      </td>
      <td v-if="isGranted('ROLE_ADMIN')">
          <button
              type="button"
              class="btn btn-secondary btn-sm"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click.stop
          >
            <i class="bi bi-share"></i>
          </button>
          <ul class="dropdown-menu">
            <li>
              <a class="dropdown-item" href="#" @click.stop.prevent="resetPasswordLink(item.id, 'bynder')">Bynder</a>
            </li>
            <li>
              <a class="dropdown-item" href="#" @click.stop.prevent="resetPasswordLink(item.id, 'dataggo')">Dataggo</a>
            </li>
          </ul>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import DateFormatter from "@/components/Utils/DateFormatter";
import SecurityMixin from "@/mixins/securityMixin";
import UserService from "@/services/user.service";
import {useToast} from "vue-toastification";

export default {
  name: "UserGrid",
  components: {DateFormatter},
  mixins: [SecurityMixin],
  props: {
    items: Array,
  },
  setup () {
    const toast = useToast();

    return {toast};
  },
  data() {
    return {
      alertMessage: null,
      alertType: "alert-success",
    };
  },
  methods: {
    async resetPasswordLink (userId, appStyle) {
      UserService.getResetPasswordLink(userId, appStyle).then((response) => {
        if (response.data) {
          this.alertMessage = this.$t('user.reset_password_link.success', { link: response.data });
          this.alertType = "alert-success";
        }
      }).catch((error) => {
        console.log(error);
        if(error.message) {
          this.toast.error(error.message);
        }
      });
    },
    closeAlert() {
      this.alertMessage = null;
    },
  }
}
</script>

<style scoped lang="scss">
button.disabled {
  opacity: 1 !important;
}

table {
  tr {
    cursor: pointer;
  }
}
</style>
