<template>
  <div class="mb-3">
    <label
        v-if="label"
        class="form-label">
      {{ label }}
    </label>

    <div class="container ms-0">
      <div class="row col-9">
        <div class="col-lg-4 col-md-6" v-for="(multibinding, index) in multibindings" :key="confInputKey + index">
          <MultibindingField
              class="mt-3"
              :id="'multibinding-' + index"
              :multibinding=multibinding
              :binding-type="bindingType"
              v-on:multibinding-save="saveMultibinding($event, index)"
              @multibinding-remove="removeMultibinding(index)"
              :errors="this.getMultibindingsErrors(index)"
          />
        </div>
      </div>
    </div>
    <button @click="addMultibinding" class="m-3 btn btn-primary">
      <i class="bi bi-plus" aria-hidden="true"/>
      {{ $t('form.bynderAkeneo.multibinding.add_multibinding') }}
    </button>
  </div>
</template>

<script>

import MultibindingField from "./MultibindingField";

export default {
  name: "MultibindingFields",
  components: {MultibindingField},
  props: {
    id: String,
    label: String,
    modelValue: Array,
    errors: Object,
    bindingType: String,
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      confInputKey: Math.random(),
      multibindings: this.modelValue
    }
  },
  methods: {
    getMultibindingsErrors (index) {
      if (this.errors && this.errors[index]) {
        return this.errors[index];
      }
      return null;
    },
    addMultibinding (e) {
      if (!this.multibindings) {
        this.multibindings = [];
      }

      this.multibindings.push({
        name: "",
        asset_family: "",
        identifiers: "",
        product_attribute: "",
        separator: "",
        product_filters: "",
        fill_only_if_empty: "",
        reference_attribute: "",
      });

      e.preventDefault();
    },
    saveMultibinding (multibinding, index) {
      this.multibindings[index] = multibinding;
      this.updateValue();
    },
    removeMultibinding (index) {
      this.multibindings.splice(index, 1);
      this.updateValue();
    },
    updateValue () {
      this.$emit(`update:modelValue`, this.multibindings);
      this.confInputKey = Math.random()
    }
  },
}
</script>
