<template>
  <GenericDataRequest
      :send-request-function="this.sendRequest"
      :connection="connection"
  >
    <template v-slot:request-fields>
      <TextField
          id="attributeCode"
          class="w-100"
          :label="$t('connection.data.shopify.metafield_definition_id')"
          v-model="metafieldDefinitionId"
      />
    </template>
  </GenericDataRequest>
</template>

<script>

import GenericDataRequest from "@/views/Connection/Data/GenericDataRequest.vue";
import ConnectionService from "@/services/connection.service";
import TextField from "@/components/Form/TextField.vue";

export default {
  name: "ShopifyMetafieldDefinitionByIdDataRequest",
  components: {
    TextField,
    GenericDataRequest,
  },
  props: {
    connection: Object,
    errors: Object,
  },
  data () {
    return {
      metafieldDefinitionId: '',
      page: 1,
    }
  },
  computed: {},
  methods: {
    sendRequest () {
      return ConnectionService.getShopifyData(this.connection.id, 'metafieldDefinitionById', {
        metafieldDefinitionId: this.metafieldDefinitionId,
        page: this.page
      })
    }
  },
}
</script>

