<template>
  <GenericDataRequest
      :send-request-function="this.sendRequest"
      :connection="connection"
  >
    <template v-slot:request-fields>
      <p>{{$t("connection.data.bynder.webhooks.description")}}</p>
    </template>
  </GenericDataRequest>
</template>

<script>

import GenericDataRequest from "@/views/Connection/Data/GenericDataRequest.vue";
import ConnectionService from "@/services/connection.service";

export default {
  name: "BynderWebhooksDataRequest",
  components: {
    GenericDataRequest,
  },
  props: {
    connection: Object,
    errors: Object,
  },
  computed: {},
  methods: {
    sendRequest () {
      return ConnectionService.getBynderData(this.connection.id, 'webhooks', {})
    }
  },
}
</script>

