<template>
  <Loader v-if="!isLoaded"/>
  <div v-if="isLoaded" class="container-fluid">
    <Breadcrumbs :items="breadcrumbs"/>
    <HeaderTitle :title="$t('job.job_instances')">
      <template v-if="isGranted('ROLE_ADMIN')" v-slot:button-area>
        <router-link class="m-1 btn btn-primary" to="/job_instance/create">
          <i class="bi bi-plus-lg"/>
          {{ $t('job.job_instances.create') }}
        </router-link>
      </template>
      <template v-slot:under-title-area>
        <p class="d-block text-muted">
          {{ totalCount }} {{ $t('common.results') }}
        </p>
      </template>
    </HeaderTitle>
    <Filters v-if="isGranted('ROLE_ADMIN')" :filters-conf="gridFiltersConf" :on-filter="filter" :base-values="filters" :grid-name="gridName"/>

    <div v-if="0 === rows.length" class="text-center mt-lg-5">
      {{ $t('job.job_instances.no_job_instances') }}
    </div>

    <Loader v-if="isGridLoading"/>
    <div v-if="!isGridLoading && rows.length > 0">
      <Pager :current-page="page" :on-page-selection="changePage" :total-count="totalCount"/>
      <table class="table table-hover">
        <thead>
        <tr>
          <th scope="col">{{ $t('common.property.label') }}</th>
          <th v-if="isGranted('ROLE_ADMIN')" scope="col">{{ $t('job.jobInstance.property.company_name') }}</th>
          <th style="width: 30%" scope="col">{{ $t('job.job_instances.job') }}</th>
          <th style="width: 30%" scope="col">{{ $t('common.property.description') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="row in rows" :key="row.id" @click="clickOnRowAction(row)">
          <td>{{ row.label ?? row.code }}</td>
          <td v-if="isGranted('ROLE_ADMIN')">{{ row.company.name }}</td>
          <td>{{ $t('job.' + row.jobCode) }}</td>
          <td>{{ row.description }}</td>
        </tr>
        </tbody>
      </table>
      <Pager :current-page="page" :on-page-selection="changePage" :total-count="totalCount"/>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Page/Loader";
import JobService from "@/services/job.service";
import HeaderTitle from "@/components/Page/Content/HeaderTitle";
import Pager from "@/components/Utils/Pager";
import Breadcrumbs from "@/components/Page/Breadcrumbs";
import SecurityMixin from "@/mixins/securityMixin";
import Filters from "@/components/Utils/Datagrid/Filters";
import {mapActions} from "vuex";

export default {
  name: "JobInstanceIndex",
  components: {Breadcrumbs, Pager, HeaderTitle, Loader, Filters},
  mixins: [SecurityMixin],
  data () {
    return {
      gridName: 'jobInstanceGrid',
      isLoaded: false,
      rows: [],
      totalCount: 0,
      page: 1,
      isGridLoading: false,
      filters: {},
    }
  },
  computed: {
    breadcrumbs () {
      return [
        {name: 'home', label: this.$i18n.t('common.home'), link: '/'},
        {name: 'job_instances', label: this.$i18n.t('job.job_instances'), isCurrent: true},
      ]
    },
    gridFiltersConf () {
      return [
        {
          code: 'company',
          type: 'async-select',
          title: this.$i18n.t('user.property.company.name'),
          options: {
            route: '/api/companies',
            searchProperty: 'name',
            resultLabel: 'name',
          }
        },
        {
          code: 'jobCode',
          type: 'async-select',
          title: this.$i18n.t('job.jobInstance.property.jobCode'),
          options: {
            route: '/api/list_jobs',
            searchProperty: 'code',
            optionIdProperty: 'code',
            optionTranslationPrefix: 'job.'
          }
        }
      ];
    }
  },
  methods: {
    ...mapActions({
      initGrid: "dataGrid/initGrid",
      updatePage: "dataGrid/updatePage",
      updateFilters: "dataGrid/updateFilters"
    }),
    changePage (page) {
      this.isGridLoading = true;
      this.page          = page;
      this.updatePage({gridName: this.gridName, page: page});
      this.loadJobInstances();
    },
    async filter (filters) {
      this.isGridLoading = true;
      this.page          = 1;
      this.filters       = filters;
      this.updateFilters({gridName: this.gridName, filters: filters});
      await this.loadJobInstances();
    },
    async loadJobInstances () {
      this.isGridLoading = true;
      await JobService.getJobInstances(this.page, this.filters)
          .then((response) => {
            this.rows       = response.data['hydra:member'];
            this.totalCount = response.data['hydra:totalItems'];
          })
          .catch(error => console.log(error))
          .finally(() => {
            this.isLoaded = true;
            this.isGridLoading = false;
          });
    },
    clickOnRowAction (row) {
      this.$router.push('/job_instance/' + row.code + '/view');
    }
  },
  beforeMount () {
    this.isLoaded = false;
    this.initGrid(this.gridName);
    this.page    = this.$store.getters['dataGrid/page'](this.gridName);
    this.filters = this.$store.getters['dataGrid/filters'](this.gridName);
    this.loadJobInstances();
  }
}
</script>

<style scoped lang="scss">
table {
  tr {
    cursor: pointer;
  }
}
</style>
