<template>
  <div class="card">
    <div class="multibinding-summary">
      <div class="card-body" @click="openModal">
        <h5 class="card-title">
          {{ name }}
        </h5>
        <p class="card-text">{{ asset_family }}</p>
        <p class="card-text">{{ product_attribute + record_attribute }}</p>
        <p class="card-text">{{ reference_entity }}</p>
        <div v-if="formattedMessages" class="invalid-feedback d-inline-block">
          <span> {{ formattedMessages }} </span>
        </div>
      </div>
    </div>
    <div class="modal fade" ref="exampleModal" tabindex="-1" aria-hidden="true" data-bs-backdrop="static"
         data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-scrollable modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ $t('job.jobInstance.bynderAkeneo.config.multibinding.' + bindingType + '.title') }}
            </h5>
          </div>
          <div class="modal-body">
            <h5>{{ $t('job.jobInstance.form.tab.generalData') }}</h5>
            <TextField
                class=""
                id="name"
                :required=true
                :error="getErrorMessage('name')"
                :label="$t('form.bynderAkeneo.multibinding.name')"
                v-model="name"
            />
            <TextField
                class=""
                id="asset_family"
                :required=true
                :error="getErrorMessage('asset_family')"
                :label="$t('form.bynderAkeneo.multibinding.asset_family')"
                v-model="asset_family"
            />
            <TextField
                v-if="bindingType==='record'"
                class=""
                id="reference_entity"
                :required=true
                :error="getErrorMessage('reference_entity')"
                :label="$t('form.bynderAkeneo.multibinding.reference_entity')"
                :tooltip="$t('form.bynderAkeneo.multibinding.static_or_asset_code')"
                :tooltip-placement="'bottom'"
                v-model="reference_entity"
            />
            <div class="row g-2">
              <div class="col-9">
                <TextField
                    id="identifiers"
                    :required=true
                    :error="getErrorMessage('identifiers')"
                    :tooltip="$t('form.bynderAkeneo.multibinding.static_or_asset_code')"
                    :tooltip-placement="'bottom'"
                    :label="$t('form.bynderAkeneo.multibinding.identifiers')"
                    v-model="identifiers"
                />
              </div>
              <div class="col-3">
                <TextField
                    :required=true
                    id="separator"
                    :error="getErrorMessage('separator')"
                    :label="$t('form.bynderAkeneo.multibinding.separator')"
                    v-model="separator"
                />
              </div>
            </div>
            <div class="row g-2">
              <div class="col-9">
                <TextField
                    id="locale"
                    :label="$t('form.bynderAkeneo.multibinding.locale')"
                    :error="getErrorMessage('locale')"
                    v-model="locale"
                />
              </div>
              <div class="col-3">
                <TextField
                    id="locale_separator"
                    :label="$t('form.bynderAkeneo.multibinding.locale_separator')"
                    :error="getErrorMessage('locale_separator')"
                    v-model="locale_separator"
                />
              </div>
            </div>
            <div class="row g-2">
              <div class="col-9">
                <TextField
                    id="channel"
                    :label="$t('form.bynderAkeneo.multibinding.channel')"
                    :error="getErrorMessage('channel')"
                    v-model="channel"
                />
              </div>
              <div class="col-3">
                <TextField
                    id="channel_separator"
                    :label="$t('form.bynderAkeneo.multibinding.channel_separator')"
                    :error="getErrorMessage('channel_separator')"
                    v-model="channel_separator"
                />
              </div>
            </div>
            <div class="row g-2">
              <div class="col-9">
                <TextField
                    v-if="bindingType==='product'"
                    id="product_attribute"
                    :required=true
                    :error="getErrorMessage('product_attribute')"
                    :label="$t('form.bynderAkeneo.multibinding.product_attribute')"
                    v-model="product_attribute"
                />
                <TextField
                    v-if="bindingType==='record'"
                    id="record_attribute"
                    :required=true
                    :error="getErrorMessage('record_attribute')"
                    :label="$t('form.bynderAkeneo.multibinding.record_attribute')"
                    v-model="record_attribute"
                />
              </div>
              <div class="col-3">
                <TextField
                    id="attribute_separator"
                    :label="$t('form.bynderAkeneo.multibinding.attribute_separator')"
                    :error="getErrorMessage('attribute_separator')"
                    v-model="attribute_separator"
                />
              </div>
            </div>
            <TextField
                v-if="bindingType==='product'"
                id="sort_order"
                :label="$t('form.bynderAkeneo.multibinding.sort_order')"
                :error="getErrorMessage('sort_order')"
                v-model="sort_order"
            />
            <ToggleField
                v-if="bindingType === 'product'"
                class="mb-3"
                id="remove_assets_from_products"
                :label="$t('form.bynderAkeneo.multibinding.remove_assets_from_products')"
                :error="getErrorMessage('remove_assets_from_products')"
                v-model="remove_assets_from_products"
            />
            <ToggleField
                id="fill_only_if_empty"
                :label="$t('form.bynderAkeneo.multibinding.fill_only_if_empty')"
                :error="getErrorMessage('fill_only_if_empty')"
                v-model="fill_only_if_empty"
            />
            <hr class="mt-5"/>
            <h5>{{ $t('job.jobInstance.form.tab.advanced_settings') }}</h5>
            <TextField
                v-if="bindingType==='product'"
                class=""
                id="reference_attribute"
                :label="$t('form.bynderAkeneo.multibinding.reference_attribute')"
                :error="getErrorMessage('reference_attribute')"
                v-model="reference_attribute"
            />
            <div v-if="bindingType==='product'">
              <ToggleField
                  id="open-product-filters"
                  :label="$t('form.bynderAkeneo.multibinding.product_filters')"
                  v-model="open_product_filters"
              />
              <JsonField
                  v-if="open_product_filters"
                  class=""
                  id="product_filters"
                  :rows=3
                  :modes="['code']"
                  v-model="product_filters"
              />
              <ToggleField
                  id="open-product-values"
                  :label="$t('form.bynderAkeneo.multibinding.product_values')"
                  v-model="open_product_values"
              />
              <JsonField
                  v-if="open_product_values"
                  class=""
                  id="product_values"
                  :showBtns="false"
                  :rows=3
                  :modes="['code']"
                  v-model="product_values"
              />
            </div>
            <div v-if="bindingType==='record'">
              <ToggleField
                  id="open-record-filters"
                  :label="$t('form.bynderAkeneo.multibinding.record_filters')"
                  v-model="open_record_filters"
              />
              <JsonField
                  v-if="open_record_filters"
                  class=""
                  id="record_filters"
                  :rows=3
                  :modes="['code']"
                  v-model="record_filters"
              />
              <ToggleField
                  id="open-record-values"
                  :label="$t('form.bynderAkeneo.multibinding.record_values')"
                  v-model="open_record_values"
              />
              <JsonField
                  v-if="open_record_values"
                  class=""
                  id="record_values"
                  :showBtns="false"
                  :rows=3
                  :modes="['code']"
                  v-model="record_values"
              />
            </div>
            <ToggleField
                v-if="remove_assets_from_products"
                class="mb-3"
                id="check_removed_locales"
                :label="$t('form.bynderAkeneo.multibinding.check_removed_locales.label')"
                :error="getErrorMessage('check_removed_locales')"
                v-model="check_removed_locales"
            />
          </div>
          <div class="modal-footer">
            <div v-if="formattedMessages" class="invalid-feedback d-inline-block">
              <span> {{ formattedMessages }} </span>
            </div>
            <button @click="removeMultibinding" class="mt-3 btn btn-sm btn-danger">
              <i class="bi bi-trash2" aria-hidden="true"/>
              {{ $t('common.remove') }}
            </button>
            <button @click="saveMultibinding" class="mt-3 btn btn-sm btn-success">
              <i class="bi bi-check" aria-hidden="true"/>
              {{ $t('common.close') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Modal} from 'bootstrap'
import TextField from "@/components/Form/TextField";
import ToggleField from "@/components/Form/ToggleField";
import JsonField from "@/components/Form/JsonField";

export default {
  name: "MultibindingField",
  components: {
    JsonField,
    TextField,
    ToggleField
  },
  props: {
    id: String,
    multibinding: Object,
    bindingType: String,
    errors: Object,
  },
  data () {
    return {
      modal: null,
      name: this.multibinding.name,
      asset_family: this.multibinding.asset_family,
      reference_entity: this.multibinding.reference_entity ?? '',
      identifiers: this.multibinding.identifiers,
      product_attribute: this.multibinding.product_attribute ?? '',
      record_attribute: this.multibinding.record_attribute ?? '',
      separator: this.multibinding.separator,
      remove_assets_from_products: this.multibinding.remove_assets_from_products,
      check_removed_locales: this.multibinding.check_removed_locales ?? true,
      product_filters: this.multibinding.product_filters !== "" ? JSON.stringify(this.multibinding.product_filters) : "",
      product_values: this.multibinding.product_values !== "" ? JSON.stringify(this.multibinding.product_values) : "",
      record_filters: this.multibinding.record_filters !== "" ? JSON.stringify(this.multibinding.record_filters) : "",
      record_values: this.multibinding.record_values !== "" ? JSON.stringify(this.multibinding.record_values) : "",
      fill_only_if_empty: this.multibinding.fill_only_if_empty,
      reference_attribute: this.multibinding.reference_attribute,
      attribute_separator: this.multibinding.attribute_separator,
      sort_order: this.multibinding.sort_order,
      locale: this.multibinding.locale,
      channel: this.multibinding.channel,
      locale_separator: this.multibinding.locale_separator,
      channel_separator: this.multibinding.channel_separator,
      open_product_filters: this.multibinding.product_filters && this.multibinding.product_filters !== "{}",
      open_product_values: this.multibinding.product_values && this.multibinding.product_values !== "{}",
      open_record_filters: this.multibinding.record_filters && this.multibinding.record_filters !== "{}",
      open_record_values: this.multibinding.record_values && this.multibinding.record_values !== "{}",
      formErrors: {},
    }
  },
  computed: {
    formattedMessages () {
      let formattedMessages = "";
      if (this.errors && Object.keys(this.errors).length !== 0) {
        for (const key in this.errors) {
          formattedMessages += `${key}: ${this.errors[key]}\n`;
        }
      }

      if (Object.keys(this.formErrors).length !== 0) {
        for (const key in this.formErrors) {
          formattedMessages += `${key}: ${this.formErrors[key]}\n`;
        }
      }

      return formattedMessages;
    },
  },
  methods: {
    openModal () {
      this.modal.show();
    },
    saveMultibinding (e) {
      e.preventDefault();

      this.formErrors          = {};
      const requiredProperties = ['asset_family', 'name', 'identifiers', 'separator'];
      for (const requiredProperty of requiredProperties) {
        if (!this[requiredProperty] || this[requiredProperty] === "") {
          this.formErrors[requiredProperty] = this.$t('common.isRequired');
        }
      }

      if (Object.keys(this.formErrors).length > 0) {
        return;
      }

      this.modal.hide();

      let conf = {
        name: this.name,
        asset_family: this.asset_family,
        identifiers: this.identifiers,
      };

      if (this.bindingType === 'product' && this.open_product_filters && JSON.parse(this.product_filters)) {
        conf.product_filters = JSON.parse(this.product_filters)
      }

      if (this.bindingType === 'product' && this.open_product_values && JSON.parse(this.product_values)) {
        conf.product_values = JSON.parse(this.product_values)
      }

      if (this.bindingType === 'record' && this.open_record_filters && JSON.parse(this.record_filters)) {
        conf.record_filters = JSON.parse(this.record_filters)
      }

      if (this.bindingType === 'record' && this.open_record_values && JSON.parse(this.record_values)) {
        conf.record_values = JSON.parse(this.record_values)
      }

      const propertiesToAdd = [
        ...(this.bindingType === 'record' ? [
          "reference_entity",
          "record_attribute",
        ] : [
          "product_attribute",
          "reference_attribute",
          "remove_assets_from_products",
          "sort_order",
        ]),
        "separator",
        "fill_only_if_empty",
        "check_removed_locales",
        "attribute_separator",
        "locale",
        "channel",
        "locale_separator",
        "channel_separator"
      ];


      for (const prop of propertiesToAdd) {
        this.addIfDefinedAndNotEmpty(conf, prop, this[prop]);
      }

      this.$emit('multibinding-save', conf);
    },
    removeMultibinding (e) {
      if (confirm(this.$t('form.bynderAkeneo.multibinding.delete_confirmation'))) {
        this.modal.hide();
        this.$emit('multibinding-remove');
      }
      e.preventDefault();
    },
    getErrorMessage (field) {
      if (this.errors && this.errors[field]) {
        return this.errors[field];
      }
      if (this.formErrors && this.formErrors[field]) {
        return this.formErrors[field];
      }

      return null;
    },
    addIfDefinedAndNotEmpty (obj, propName, propValue) {
      if (propValue !== undefined && propValue !== null && propValue !== "") {
        obj[propName] = propValue;
      }
    }
  },
  mounted () {
    this.modal = new Modal(this.$refs.exampleModal)
    if (this.asset_family === ""
        || this.name === ""
        || this.identifiers === ""
        || (this.product_attribute === "" && this.record_attribute === "")
    ) {
      this.modal.show();
    }
  },
}
</script>

<style lang="scss">

.multibinding-summary {
  cursor: pointer;

  .card-body {
    min-height: 120px;
  }

  :hover {
    background-color: lightgray;
  }
}

</style>
