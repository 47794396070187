<template>
  <GenericDataRequest
      :send-request-function="this.sendRequest"
      :connection="connection"
  >
    <template v-slot:request-fields>
      <TextField
          id="attributeCode"
          class="w-100"
          :label="$t('connection.data.shopify.file_id')"
          v-model="fileId"
      />
    </template>
  </GenericDataRequest>
</template>

<script>

import GenericDataRequest from "@/views/Connection/Data/GenericDataRequest.vue";
import ConnectionService from "@/services/connection.service";
import TextField from "@/components/Form/TextField.vue";

export default {
  name: "ShopifyFileByIdDataRequest",
  components: {
    TextField,
    GenericDataRequest,
  },
  props: {
    connection: Object,
    errors: Object,
  },
  data () {
    return {
      fileId: '',
      page: 1,
    }
  },
  computed: {},
  methods: {
    sendRequest () {
      return ConnectionService.getShopifyData(this.connection.id, 'fileById', {
        fileId: this.fileId,
        page: this.page
      })
    }
  },
}
</script>

